var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories"},[_c('section-title',{attrs:{"title":"Category list","link":"category-add"}}),(_vm.items && _vm.items.length)?_c('v-card',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","footer-props":{
      'items-per-page-options': [10, 25, 50, 100],
    }},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
    var item = ref.item;
return [_c('img',{attrs:{"src":item.image}})]}},{key:"item.url",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.url)+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'category-edit',
          params: { id: item.id },
        }}},[_c('v-btn',{attrs:{"size":"sm","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1)],1),_c('v-btn',{attrs:{"size":"sm","icon":""},on:{"click":function($event){return _vm.removeDialog(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete")])],1)]}}],null,true)})],1):_c('div',[_c('empty-alert')],1),_c('remove-dialog',{on:{"delete":_vm.removeItem},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }